import React, { useState } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import DrugListForm from './DrugListForm';
import DrugListTable from './DrugListTable';

const NavItem = ({ item }) => {
    return (
        <Nav.Item as="li">
            <Nav.Link
                className="mb-0 py-3 cursor-pointer"
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};


const DrugList = () => {

    const [navItems] = useState(['Active', 'Inactive']);

    const [trigger, setTrigger] = useState(0);
    const [edittrigger, setEditTrigger] = useState(0);
    const [druglistval, setDrugListVal] = useState('');
    //let allergy_val = "";

    const callAnotherChildMethod = () => {
        //console.log("Getting Trigger " + trigger);
        setTrigger((trigger) => trigger + 1);
    };

    const callAnotherEditChildMethod = (editValue) => {
        //console.log("Getting Edit Trigger " + edittrigger + editValue);
        setEditTrigger((edittrigger) => edittrigger + 1);
        setDrugListVal(editValue);
    };

    return (
        <Row className="g-3">
            <Col xs={12}>
                <Card className="mt-3">
                    <Card.Body>
                        <DrugListForm status={edittrigger > 0 ? "Edit" : "Add"}
                            edittrigger={edittrigger} onCallParent={callAnotherChildMethod} druglistvalue={druglistval} />
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={12}>
                <Card className="mt-3">
                    <Card.Body>
                        <DrugListTable active_status="active" trigger={trigger} onCallEditParent={callAnotherEditChildMethod} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DrugList;
