import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const MedSplInstrForm = (props) => {

    const initialFormData = {
        splinstr: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const formRef = useRef(null);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setMedSplDetails() {
            //console.log("set edit details " + props.allergyvalue);
            setFormData({
                splinstr: props.medsplvalue.split(",")[0],
                isactive: props.medsplvalue.split(",")[1] == 1 ? true : false,
                id: props.medsplvalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setMedSplDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsSplInstrUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/add-prescription-instruction/" : `${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/update-prescription-instruction/";
        let pelmedsSplInstrData = buttonStatus == "Add" ? { "instruction": formData.splinstr, "is_active": formData.isactive ? 1 : 0 } :
            { "instruction": formData.splinstr, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        //console.log("pelmedsSplInstrData" + JSON.stringify(pelmedsSplInstrData));

        const { data } = await axios.post(pelmedsSplInstrUrl, pelmedsSplInstrData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status && buttonStatus == "Add") {
            toast.success("Special Instruction added successfully");
            //navigate(0);
        }
        else if ((data.status && buttonStatus == "Edit")) {
            toast.success("Special Instruction updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Special Instruction");
        }

        //if (buttonStatus != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Special Instruction</Form.Label>
                        <Form.Control
                            placeholder='Special Instruction Name'
                            value={formData.splinstr}
                            name="splinstr"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            disabled={!formData.splinstr}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type='switch'
                            name="isactive"
                            id='isactive'
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
export default MedSplInstrForm;
