import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const MedSplInstrTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'instruction',
            header: 'Special Instruction',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.instruction + "," + original.is_active + "," + original.instruction_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [splInstr, setSplInstr] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditSplInstrModal = (instructionValue, isActive, instructionId) => {
    //     localStorage.setItem("instructionValue", instructionValue);
    //     localStorage.setItem("instructionId", instructionId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchSplInstr() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/list-prescription-instruction", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const splInstrData = data.data

            if (data.status) {
                splInstrData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            instruction_id: value.id,
                            instruction: value.instruction,
                            // updated_by: value.updated_by,
                            is_active: value.is_active// == 1 ? 0 : 1
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setSplInstr(results);
            }
            else {
                toast.info("No Special Instruction found in this list");
            }
        }

        fetchSplInstr();

        async function refreshSplDetails() {
            fetchSplInstr();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshSplDetails();
        }
    }, [props.trigger]);

    const table = useAdvanceTable({
        data: splInstr,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditSplInstrModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedSplInstrTable;
