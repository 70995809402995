import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';

const HcpModal = ({ show, setShow, handleHcpModalValue }) => {

    let currentModalFormValue = "";

    currentModalFormValue = "Speciality"


    const [hcpModalData, setHcpModalData] = useState(" ");

    const handleCallbackSubmit = e => {
        e.preventDefault();
        setShow(false);
        handleHcpModalValue(hcpModalData);
        setHcpModalData("");
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Speciality
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form onSubmit={handleCallbackSubmit} name="specialitymodalform">
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{currentModalFormValue}</Form.Label>
                                <Form.Control
                                    value={hcpModalData}
                                    name="specialitytextbox"
                                    onChange={e => setHcpModalData(e.target.value)}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnspecialitymodalsubmit"
                                    className="mt-4 w-50">
                                    Add
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

HcpModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default HcpModal;
