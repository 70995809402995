import avatar from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import axios from "axios";


const PatientDetailsHeader = (props) => {

    //console.log("patientDetailsName" + props.username + props.facilityid + props.facility_name + props.patientid);
    const [groupName, setGroupName] = useState(null);

    useEffect(() => {
        populateData();
    });

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");

        await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/group-profile/` + localStorage.getItem("groupId"), { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                //console.log("Res " + JSON.stringify());
                setGroupName(res.data.data[0].name);
            });
    }

    return (
        <Card>
            <Card.Header className="pb-0 pt-4">
                <h4 className="mb-1">
                    {props.username}
                </h4>
            </Card.Header>
            <Card.Body className="py-0">
                <Row>
                    <Col lg={4}>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Allergies:</h6>
                            </div>
                        </Flex>
                    </Col>
                    <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Facility Name: {props.facility_name} </h6>
                            </div>
                        </Flex>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Group Name: {groupName}</h6>
                            </div>
                        </Flex>
                    </Col>

                    <Row className="col-12 mx-0 w-100">
                        <Avatar
                            size="auto"
                            className="avatar-profile col-1 mx-0 px-0"
                            src={avatar}
                            mediaClass="img-thumbnail shadow-sm"
                        />
                        <Col lg={10} className="border-top px-0 py-2 mt-3 ms-auto me-0 text-end">
                            <div className='d-inline mx-2 h6'>
                                <Link to={{ pathname: paths.addPrescription }} state={{
                                    facilityid: props.facilityid,
                                    patientid: props.patientid,
                                    orderid: null
                                }} className='text-primary'>Add Prescription</Link>
                            </div>
                            <div className='d-inline mx-2 h6'>
                                <Link to={{ pathname: paths.addMedicalRecord }} state={{
                                    facilityid: props.facilityid,
                                    patientid: props.patientid,
                                    medrecord_id: null
                                }} className='text-success' >Add Medical Record</Link>
                            </div>
                        </Col>
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PatientDetailsHeader;
