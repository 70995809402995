import React, { useState } from 'react';
import { Nav, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import NineDotMenu from './NineDotMenu';
import { Dropdown } from 'react-bootstrap';
import ThemeControlDropdown from './ThemeControlDropdown';
import paths from 'routes/paths';
import logout from 'assets/img/icons/logout.svg';
import Modal from 'react-bootstrap/Modal';
import team3 from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import MyProfile from './MyProfile';
import ResetPassword from './ResetPassword';

const NavItem = ({ item, classNameCustom }) => {
  return (
    console.log(classNameCustom),
    <Nav.Item as="li" className={`${classNameCustom}`}>
      <Nav.Link
        className={`mb-0 py-3 px-1 cursor-pointer`}
        eventKey={item.toLowerCase()}>
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const TopNavRightSideNavItem = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // const handleClose = () => {
  //   //setShow(false);
  //   navigate("/");
  // };

  const handleOnlyClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const [navItems] = useState(['Profile', 'Reset-Password']);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <ThemeControlDropdown />
      <div className="p-2" onClick={handleShow}>
        <Avatar src={team3} />
      </div>
      <Dropdown.Item as={Link} to={paths.pelMedsLogin} className="m-2 w-auto">
        <img src={logout} alt="logout" width={20} />
      </Dropdown.Item>

      <Modal show={show} onHide={handleOnlyClose} size="xl">
        <Card.Header as="h6" className="bg-body-tertiary p-3">
          <span>Hello {localStorage.getItem("username")}</span>
        </Card.Header>
        <Card.Body className="p-3">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first" className="p-3">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="p-3">Reset Password</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <MyProfile />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <ResetPassword />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Modal>
    </Nav >
  );
};

export default TopNavRightSideNavItem;
