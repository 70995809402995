import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { useNavigate } from "react-router-dom";
import FileManagerGrid from './FileManagerGrid';

const FileManager = ({ hasLabel, layout }) => {
    // State
    const [formData, setFormData] = useState({
        docUpload: '',
        docDescription: ''
    });

    const navigate = useNavigate();

    // Handler
    const handleSubmit = e => {
        e.preventDefault();

        /*fetch(`${process.env.REACT_APP_SERVER_URL}`+"/users/login",
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': formData.username, 'password': formData.password })
          })
          .then(response => response.json())
          .then(function (data) {
            const items = data;
            console.log("Loginform " + items.status);
            if (items.status) {
              localStorage.setItem("accessToken", items.token);
              toast.success(`Logged in as ${formData.username}`, {
                theme: 'colored'
              });
              navigate("/main");
            }
            else {
              localStorage.setItem("hash", items.hash);
              toast.warning(`Email Needs to be verified ${formData.username}`, {
                theme: 'colored'
              });
              navigate("/otp");
            }
          })*/

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Document Upload</Form.Label>
                        <Form.Control size="sm" type="file" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Document Description</Form.Label>
                        <Form.Control size="sm" type="text" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="w-50 mt-4"
                            disabled={!formData.username || !formData.password}>
                            Upload
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FileManagerGrid />
                </Col>
            </Row>
        </Form>
    );
};

FileManager.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

FileManager.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default FileManager;
