import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import DrugListModal from './DrugListModal';

const DrugListTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'drug',
            header: 'Drug',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'strength',
            header: 'Strength',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'ndc',
            header: 'NDC',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'brand_drug',
            header: 'Brand',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'generic',
            header: 'Generic',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'drug_form',
            header: 'DrugForm',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'caution1',
            header: 'Caution1',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'caution2',
            header: 'Caution2',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'caution3',
            header: 'Caution3',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.drug_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [druglist, setDrugList] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditDrugModal = (drugId) => {
    //     localStorage.setItem("drugId", drugId);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchDrugList() {

            const drugData = JSON.parse(localStorage.getItem("ogdrugData"));

            drugData.forEach((value) => {
                results.push({
                    drug: value.drug,
                    strength: value.strength,
                    ndc: value.ndc,
                    generic: value.generic,
                    brand_drug: value.brand_drug,
                    caution1: value.caution1,
                    caution2: value.caution2,
                    caution3: value.caution3,
                    drug_form: value.drug_form,
                    drug_id: value.id
                });
            });

            setDrugList(results);
        }

        fetchDrugList();

        async function refreshDrugListDetails() {
            fetchDrugList();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshDrugListDetails();
        }
    }, [props.trigger]);

    const table = useAdvanceTable({
        data: druglist,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <DrugListModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default DrugListTable;
