import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import MedSheetForm from './MedSheetForm';
import CurrentMed from './CurrentMed';
import Treatment from './Treatment';
import LoaForm from './LoaForm';
import HcpOrder from './HcpOrder';


const FacilityReportIndex = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {

        populateFacilityDetails();

    }, []);

    async function populateFacilityDetails() {

        //let tokenStr = localStorage.getItem("accessToken");
        //let facilityName = "";

        let facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
        //console.log("Facility Points " + results);

        setOptions([
            { key: 'Select a facility', value: '' },
            ...facilityResults
        ])

    }

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        localStorage.setItem("facilityId", e.target.value);
    };

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                {props.formname}
            </Card.Header>
            <Card.Body className="p-3">
                <Row className='mt-3 justify-content-between'>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Label>Facility</Form.Label>
                            <Form.Select
                                name="facilityname"
                                onChange={handleFieldChange}
                            >
                                {options.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col xs="12" className="mb-1 mt-1">
                        {props.formname == "Medsheet" &&
                            <MedSheetForm />
                        }
                        {props.formname == "Currentmeds" &&
                            <CurrentMed />
                        }
                        {props.formname == "Treatment" &&
                            <Treatment />
                        }
                        {props.formname == "Loaform" &&
                            <LoaForm />
                        }
                        {props.formname == "Hcporder" &&
                            <HcpOrder />
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default FacilityReportIndex;