

export const loaFormData = [
    {
        medication: 'BACITRACIN',
        strength: '500 UNIT/GRAM',
        amount: 1,
        frequency: 'four times a day',
        quantity: ''
    },
    {
        medication: 'clonidine HCl',
        strength: '0.1MG',
        amount: 1,
        frequency: '',
        quantity: ''
    },
    {
        medication: 'clozapine',
        strength: '100MG',
        amount: 0.5,
        frequency: '',
        quantity: ''
    },
    {
        medication: 'clozapine',
        strength: '200MG',
        amount: 1,
        frequency: 'Night',
        quantity: ''
    },
    {
        medication: 'CARBAMIDE PEROXIDE',
        strength: '6.5%',
        amount: 0,
        frequency: '',
        quantity: ''
    },
    {
        medication: 'guaifenesin',
        strength: '100 MG/5 ML',
        amount: 10,
        frequency: '',
        quantity: ''
    }
];