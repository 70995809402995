import React, { useEffect } from 'react';
import PatientDetailsHeader from './PatientDetailsHeader';
import PatientInfo from './PatientInfo';
import { useLocation } from 'react-router-dom';

const PatientDetails = () => {
    //const { state } = props.location;
    const location = useLocation();
    const data = location.state;
    //Todo Need to remove and fetch it from PatientID API Call
    //console.log("patientName" + data.name + data.facilityid + data.facility + data.patientid);

    useEffect(() => {
        populateData();
    });

    async function populateData() {
        localStorage.setItem("patientName", data.name);
        localStorage.setItem("patientId", data.patientid);
        localStorage.setItem("facilityId", data.facilityid);
    }

    return (
        <>
            <PatientDetailsHeader username={data.name} facility_name={data.facility} patientid={data.patientid} facilityid={data.facilityid} />
            <br /><br />
            <PatientInfo patient_id={data.patientid} username={data.name} facilityid={data.facilityid} />
        </>
    );
};

export default PatientDetails;
