import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/21.jpeg';
import Flex from 'components/common/Flex';

const Login = () => {

  localStorage.clear();

  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: 'center bottom', style:{aspectRatio: '960/1080'} }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Login</h3>
        {/*<p className="mb-0 fs-10">
          <span className="fw-semibold">New User? </span>
          <Link to={paths.splitRegister}>Create account</Link>
        </p>*/}
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;
