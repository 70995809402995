import React from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  totalSales,
  weeklySalesData,
  weather,
  products,
  storageStatus,
  files,
  users,
  topProducts,
  runningProjects
} from 'data/dashboard/default';

import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import axios from "axios";
import { useEffect } from 'react'
import Weather from './Weather';

const Dashboard = () => {


  useEffect(() => {

    async function setInitialData() {

      let tokenStr = localStorage.getItem("accessToken");
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/facilities/list-facility/", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
      let results = [];
      let drugResults = [];

      //console.log("Facilties Data Status " + data.status);
      let objData = data.data;
      let groupId = "";

      objData.forEach((value) => {
        groupId = value.group_id;
        results.push({
          key: value.facility_name,
          value: value.id,
        });
      });

      localStorage.setItem("groupId", groupId);
      localStorage.setItem("facilitiesData", JSON.stringify(results));

      await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/list-drugs/`, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
        .then((res) => {

          let objDrugData = res.data.drugs.data;

          //localStorage.setItem("ogdrugData", JSON.stringify(objDrugData));

          objDrugData.forEach((value) => {
            let drugBrand = value.brand_drug != null ? value.brand_drug : "";
            let drugNdc = value.ndc != null ? value.ndc : "";
            let drugForm = value.drug_form != null ? value.drug_form : "";
            let drugGeneric = value.generic != null ? value.generic : "";

            drugResults.push({
              key: value.drug,
              value: value.id + "|" + value.drug + "|" + drugBrand + "|" + drugNdc + "|" + drugForm + "|" + drugGeneric,
            });
          });

          //console.log("DrugResults " + drugResults);
          localStorage.setItem("drugData", JSON.stringify(drugResults));

        });

    }

    setInitialData();

  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>

      {/*<Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={6}>
          <TotalSales data={totalSales} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <SpaceWarning />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={files} className="h-lg-100" />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <BandwidthSaved />
        </Col>
        <Col xxl={6}>
          <TopProducts data={topProducts} className="h-100" />
        </Col>
      </Row>*/}
    </>
  );
};

export default Dashboard;
