import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Col, Row, Button } from 'react-bootstrap';

const MedicalRecordModal = ({ show, setShow, handleMedicalRecordModalValue }) => {

    //let formName = localStorage.getItem("medicalRecordFormName");

    let currentModalFormValue = "";

    currentModalFormValue = "Medical Special Instruction"

    const [medicalrecordModalData, setMedicalRecordModalData] = useState("");

    const handleCallbackSubmit = e => {
        e.preventDefault();
        setShow(false);
        handleMedicalRecordModalValue(medicalrecordModalData);
        setMedicalRecordModalData("");
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    <p>Medical Special Instruction</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form onSubmit={handleCallbackSubmit} name="medicalrecordmodalform">
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{currentModalFormValue}</Form.Label>
                                <Form.Control
                                    value={medicalrecordModalData}
                                    name="medicalrecordtextbox"
                                    onChange={e => setMedicalRecordModalData(e.target.value)}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnmedicalrecordmodalsubmit"
                                    className="mt-4 w-50">
                                    Add
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

MedicalRecordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default MedicalRecordModal;
