import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CurrentPrescription from 'components/prescription/CurrentPrescription';
import MedicalRecordTablePrescription from 'components/patient/medicalrecord/MedicalRecordTablePrescription';
import FileManager from 'components/utilities/FileManager';
import PatientDemographics from './PatientDemographics';
import GenerateReport from 'components/reports/GenerateReport';
import AddEditPatient from '../add-patient/AddEditPatient';

const NavItem = ({ item, classNameCustom }) => {
    return (
        console.log(classNameCustom),
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-3 px-1 cursor-pointer`}
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const PatientInfo = (props) => {
    const [navItems] = useState(['Prescription', 'Medical-Record', 'Prescription-History', 'Patient-Profile', 'File-Manager', 'Reports']);
    return (
        <Card className="mt-3">
            <Tab.Container id="audience-tab" defaultActiveKey="prescription">
                <Card.Header
                    as={Flex}
                    justifyContent="between"
                    alignItems="center"
                    className="p-0 border-bottom patientInfoTabs"
                >
                    <Nav
                        as="ul"
                        className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                    >
                        {navItems.map(item => (
                            <NavItem classNameCustom="col text-center" item={item} key={item} />
                        ))}
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <Row className="g-1">
                        <Col xxl={12}>
                            <Tab.Content>
                                <Tab.Pane unmountOnExit eventKey="prescription">
                                    <CurrentPrescription patientid={props.patient_id} facilityid={props.facilityid} />
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="medical-record">
                                    <MedicalRecordTablePrescription patientid={props.patient_id} facilityid={props.facilityid} />
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="prescription-history">
                                    <p>No History To Display</p>
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="patient-profile">
                                    <AddEditPatient patientid={props.patient_id} facilityid={props.facilityid} />
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="file-manager">
                                    <FileManager />
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="reports">
                                    <GenerateReport />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Card.Body>
            </Tab.Container>
        </Card>
    );
};

NavItem.propTypes = {
    item: PropTypes.string.isRequired
};

export default PatientInfo;