import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PharmacyModal from './PharmacyModal';
import moment from 'moment';


const PharmacyTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'pharmacy_number',
            header: 'Pharmacy Number',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'pharmacy',
            header: 'Pharmacy',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'pharmacy_contact_number',
            header: 'Pharmacy Contact Number',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'pharmacy_email',
            header: 'Pharmacy Email',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'pharmacy_fax',
            header: 'Pharmacy Fax',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updated_date',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.pharmacy_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [pharmacy, setPharmacy] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditPharmacyModal = (pharmacyId) => {
    //     localStorage.setItem("pharmacyId", pharmacyId);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchPharmacy() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/drugs/list-pharmacy-stores", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const pharmacyData = data.data

            if (data.status) {
                pharmacyData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            pharmacy_number: value.pharmacy_store_number,
                            pharmacy: value.pharmacy_store_name,
                            pharmacy_contact_number: value.phone,
                            pharmacy_email: value.email,
                            pharmacy_fax: value.fax,
                            updated_by: value.updated_by,
                            updated_date: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null,
                            pharmacy_id: value.id
                        });
                    }
                });

                setPharmacy(results);
            }
            else {
                toast.info("No Pharmacy Record found in this list");
            }
        }

        fetchPharmacy();

        async function refreshPharmacy() {
            fetchPharmacy();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshPharmacy();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: pharmacy,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <PharmacyModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default PharmacyTable;
