import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const PharmacyForm = (props) => {

    const initialFormData = {
        pharmacy: '',
        pharmacynumber: '',
        pharmacyemail: '',
        pharmacyfax: '',
        is_active: false,
        is_default: false
    };

    const [formData, setFormData] = useState(initialFormData);
    const formRef = useRef(null);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    //const navigate = useNavigate();

    useEffect(() => {
        //populateData();

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
            populateData();
        }

    }, [props.edittrigger]);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");

        //if (buttonStatus == "Edit") {
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/get-pharmacy-store/` + props.pharmacyid,
            { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                let objPharmacyData = res.data.data;

                objPharmacyData.forEach((value) => {
                    setFormData({
                        pharmacy: value.pharmacy_store_name,
                        pharmacynumber: value.pharmacy_store_number,
                        pharmacyemail: value.email,
                        pharmacyfax: value.fax,
                        is_active: value.is_active == 1 ? false : true,
                        is_default: value.is_default == 1 ? false : true
                    })

                });

            });
        //}
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");

        let pelmedsPharmacyUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/drugs/add-pharmacy-store" :
            `${process.env.REACT_APP_SERVER_URL}` + "/drugs/update-pharmacy-store";

        let pelmedsPharmacyData = buttonStatus == "Add" ?
            {
                "pharmacy_store_name": formData.pharmacy,
                "pharmacy_store_number": formData.pharmacynumber,
                "email": formData.pharmacyemail,
                "fax": formData.pharmacyfax,
                "is_active": formData.is_active ? 1 : 0,
                "is_default": formData.is_default ? 1 : 0
            } :
            {
                "id": props.pharmacyid,
                "pharmacy_store_name": formData.pharmacy,
                "pharmacy_store_number": formData.pharmacynumber,
                "email": formData.pharmacyemail,
                "fax": formData.pharmacyfax,
                "is_active": formData.is_active ? 1 : 0,
                "is_default": formData.is_default ? 1 : 0
            }

        console.log("pelmedsPharmacyData" + JSON.stringify(pelmedsPharmacyData));

        const { data } = await axios.post(pelmedsPharmacyUrl, pelmedsPharmacyData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status && buttonStatus == "Add") {
            toast.success("Pharmacy added successfully");
            //navigate(0);
        }
        else if ((data.status && buttonStatus == "Edit")) {
            toast.success("Pharmacy updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Pharmacy");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Pharmacy Name</Form.Label>
                        <Form.Control
                            placeholder='pharmacy name'
                            value={formData.pharmacy}
                            name="pharmacy"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Pharmacy Store Number</Form.Label>
                        <Form.Control
                            placeholder='Pharmacy Store Number'
                            value={formData.pharmacynumber}
                            name="pharmacynumber"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            placeholder='Email'
                            value={formData.pharmacyemail}
                            name="pharmacyemail"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                            placeholder='Fax'
                            value={formData.pharmacyfax}
                            name="pharmacyfax"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_active"
                        id='is_active'
                        checked={
                            formData.is_active
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_active: e.target.checked
                            })
                        }
                        label='Active'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_default"
                        id='is_default'
                        checked={
                            formData.is_default
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_default: e.target.checked
                            })
                        }
                        label='Is Default Pharmacy'
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            disabled={!formData.pharmacy}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
export default PharmacyForm;
