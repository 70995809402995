import React from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { toast } from 'react-toastify';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Spinner from 'react-bootstrap/Spinner';

const columns = [
    {
        accessorKey: 'month',
        header: 'Report Date',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'download',
        header: 'Report Download',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    }
];

//const [medsheet, setMedSheet] = useState([]);

const Treatment = (props) => {

    const [treatmentrpt, setTreatment] = useState("");
    const [checked, setChecked] = React.useState(true);
    const [spinner, showSpinner] = useState(false);

    const [formData, setFormData] = useState({
        reportdate: '',
        reportformat: '',
        is_drug_hcp: '',
        is_medgroup: false,
        is_prn: false,
        is_regular: false
    });

    const [reportdate, setReportDate] = useState(null);
    const [showResults, setShowResults] = React.useState(false)

    const navigate = useNavigate();

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();
        showSpinner(true);

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsTreatmentUrl = `${process.env.REACT_APP_SERVER_URL}` + "/reports/treatment";

        let pelmedsTreatmentData = localStorage.getItem("patientId") != null ?
            {
                date: reportdate != null ? moment(reportdate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,//"01-10-2024",
                patient_id: localStorage.getItem("patientId"),
                treament_format: formData.reportformat,
                is_hcp: formData.is_drug_hcp == "hcp" ? 1 : 0,
                is_drug: formData.is_drug_hcp == "drug" ? 1 : 0,
                is_medgroup: formData.is_medgroup ? 1 : 0,
                is_prn: formData.is_prn ? 1 : 0,
                is_regular: formData.is_regular ? 1 : 0
            } :
            {
                date: reportdate != null ? moment(reportdate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,//"01-10-2024",
                facility_id: localStorage.getItem("facilityId"),
                treament_format: formData.reportformat,
                is_hcp: formData.is_drug_hcp == "hcp" ? 1 : 0,
                is_drug: formData.is_drug_hcp == "drug" ? 1 : 0,
                is_medgroup: formData.is_medgroup ? 1 : 0,
                is_prn: formData.is_prn ? 1 : 0,
                is_regular: formData.is_regular ? 1 : 0
            };

        //console.log("pelmedsTreatmentData " + JSON.stringify(pelmedsTreatmentData));

        const { data } = await axios.post(pelmedsTreatmentUrl, pelmedsTreatmentData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {

            //setTreatment(data.medsheet_url);
            //setShowResults(true);

            //console.log(JSON.stringify(treatmentrpt));

            toast.success("Treatment Report Generated Successfully");
            showSpinner(false);
            props.onCallParent();
        }

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleRadioChange = e => {
        setChecked((state) => !state)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Date</Form.Label>
                            <DatePicker name="reportdate" selected={reportdate} onChange={(reportdate) => setReportDate(reportdate)}
                                showMonthYearPicker className='form-control' placeholderText="Select Report Date" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Format</Form.Label>
                            <Form.Select name="reportformat"
                                onChange={handleFieldChange}>
                                <option value=''>Select your Format</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Sort By</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='radio'
                                        name="is_drug_hcp"
                                        id='is_drug'
                                        value="drug"
                                        onChange={handleRadioChange}
                                        label='Drug'
                                        defaultChecked={checked}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='radio'
                                        name="is_drug_hcp"
                                        id='is_hcp'
                                        value="hcp"
                                        onChange={handleRadioChange}
                                        label='Hcp'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Treatment For</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_regular"
                                        id='is_regular'
                                        checked={
                                            formData.is_regular
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_regular: e.target.checked
                                            })
                                        }
                                        label='Regular'
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_prn"
                                        id='is_prn'
                                        checked={
                                            formData.is_prn
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_prn: e.target.checked
                                            })
                                        }
                                        label='Prn'
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_medgroup"
                                        id='is_medgroup'
                                        checked={
                                            formData.is_medgroup
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_medgroup: e.target.checked
                                            })
                                        }
                                        label='MedGroup'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form.Group>
                            {spinner && (
                                <Button variant="primary" disabled className="mt-3 w-100">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="p-2" style={{ "verticalAlign": "3px" }}>Fetching...</span>
                                </Button>
                            )}
                            {!spinner && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-3 w-100">
                                    Generate
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <br />
            {showResults && (
                <a href={treatmentrpt} target="_blank" rel="noreferrer">Download Report</a>

            )}
        </div>
    );
};

Treatment.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

Treatment.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default Treatment;
