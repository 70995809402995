import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Card, Col, Form, Row, Tab, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from "axios";

const ResetPassword = () => {

  let initialFormData = {
    oldpassword: '',
    newpassword: '',
    cnfpassword: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    let resetPasswordData =
    {
      "password": formData.newpassword,
      "confirm_password": formData.cnfpassword
    };

    let tokenStr = localStorage.getItem("accessToken");

    let resetPasswordUrl = `${process.env.REACT_APP_SERVER_URL}/users/change-password`;

    const { data } = await axios.post(resetPasswordUrl, resetPasswordData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    console.log("data " + JSON.stringify(data));

    if (data.status) {
      toast.success("Password updated successfully");
      navigate("/");
    }
    else {
      toast.error("Error encountered while updating the password");
    }
  }

  return (
    <Form onSubmit={handleSubmit} name="passwordform">
      <Card className="p-2">
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">Old Password</Form.Label>
              <Form.Control
                placeholder='Old Password'
                value={formData.oldpassword}
                name="oldpassword"
                onChange={handleFieldChange}
                type="text"
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">New Password</Form.Label>
              <Form.Control
                placeholder='New Password'
                value={formData.newpassword}
                name="newpassword"
                onChange={handleFieldChange}
                type="text"
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">Confirm Password</Form.Label>
              <Form.Control
                placeholder='Confirm Password'
                value={formData.cnfpassword}
                name="cnfpassword"
                onChange={handleFieldChange}
                type="text"
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col></Col>
          <Col md="3">
            <Form.Group>
              <Button variant="primary" type="submit">
                Reset Password
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Card >
    </Form>
  );
};

export default ResetPassword;
