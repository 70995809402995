import React, { useState } from 'react';
import { Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import HcpTable from './HcpTable.js'
import Flex from 'components/common/Flex';

const NavItem = ({ item }) => {
    return (
        <Nav.Item as="li">
            <Nav.Link
                className="mb-0 py-3 cursor-pointer"
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};


const ViewHcp = () => {

    const [navItems] = useState(['Active-HCP', 'Inactive-HCP']);

    return (
        <Row className="g-3">
            <Col lg={12}>
                <Card className="mt-3">
                    <Tab.Container id="audience-tab" defaultActiveKey="active-hcp">
                        <Card.Header
                            as={Flex}
                            justifyContent="between"
                            alignItems="center"
                            className="ps-0 py-0 border-bottom">
                            <Nav
                                as="ul"
                                className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret">
                                {navItems.map(item => (
                                    <NavItem item={item} key={item} />
                                ))}
                            </Nav>
                            <CardDropdown />
                        </Card.Header>

                        <Card.Body>
                            <Row className="g-1">
                                <Col xxl={12}>
                                    <Tab.Content>
                                        <Tab.Pane unmountOnExit eventKey="active-hcp">
                                            <HcpTable active_status="m" />
                                        </Tab.Pane>
                                        <Tab.Pane unmountOnExit eventKey="inactive-hcp">
                                            <HcpTable active_status="f" />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </Col>
        </Row>
    );
};

export default ViewHcp;
