import React from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import LoaFormGrid from './LoaFormGrid';
import Spinner from 'react-bootstrap/Spinner';

const LoaForm = (props) => {
    // State
    const [formData, setFormData] = useState({
        docUpload: '',
        docDescription: ''
    });

    const [date, setDate] = useState(null);
    const [spinner, showSpinner] = useState(false);

    const navigate = useNavigate();

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        showSpinner(true);
        showSpinner(false);
        props.onCallParent();


    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Departure</Form.Label>
                        <DatePicker
                            popperPlacement='bottom-start'
                            selected={date}
                            onChange={(date) => setDate(date)}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control'
                            placeholderText="Select Date & Time"
                            timeIntervals={5}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeSelect
                            fixedHeight
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Return</Form.Label>
                        <DatePicker
                            popperPlacement='bottom-start'
                            selected={date}
                            onChange={(date) => setDate(date)}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control'
                            placeholderText="Select Date & Time"
                            timeIntervals={5}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeSelect
                            fixedHeight
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>HCP</Form.Label>
                        <Form.Select size="sm">
                            <option value=''>Select your HCP</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Report Format</Form.Label>
                        <Form.Select size="sm">
                            <option value=''>Select your Format</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    <Form.Group>
                        {spinner && (
                            <Button variant="primary" disabled className="mt-3 w-100">
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="p-2" style={{ "verticalAlign": "3px" }}>Fetching...</span>
                            </Button>
                        )}
                        {!spinner && (
                            <Button
                                type="submit"
                                color="primary"
                                className="mt-3 w-100">
                                Generate
                            </Button>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3">
                    <LoaFormGrid />
                </Col>
            </Row>
        </Form>
    );
};

LoaForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoaForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoaForm;
