import React from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import OtpForm from 'components/authentication/OtpForm';
import bgImg from 'assets/img/generic/21.jpeg';
import Flex from 'components/common/Flex';

const Otp = () => {
    return (
        <AuthSplitLayout bgProps={{ image: bgImg, position: 'center bottom' }}>
            <Flex alignItems="center" justifyContent="between">
                <p>One Time Password (OTP)</p>
            </Flex>
            <OtpForm layout="split" hasLabel />
        </AuthSplitLayout>
    );
};

export default Otp;