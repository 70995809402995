import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import paths from 'routes/paths';
import ResetPasswordModal from '../add-facility-staff/ResetPasswordModal';

const ViewFacilityStaff = () => {
    //const [facility] = useState(facilityStaffData);
    const [facilityStaff, setFacilityStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Username',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            },
            cell: ({ row: { original } }) => {
                return <Link to={{ pathname: paths.addFacility }} state={{
                    userid: original.id
                }} >
                    {original.name}</Link>
            }
        },
        {
            accessorKey: 'fullname',
            header: 'Fullname',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'role',
            header: 'Role',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'mobile',
            header: 'Mobile',
            meta: {
                headerProps: { className: 'ps-5 text-900' }
            }
        },
        {
            accessorKey: 'email',
            header: 'Email',
            meta: {
                headerProps: { className: 'ps-5 text-900' }
            }
        },
        {
            accessorKey: 'active',
            header: 'Active',
            meta: {
                headerProps: { className: 'text-900' }
            }
        }
        ,
        {
            accessorKey: 'lastlogin',
            header: 'Last Login',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: '',
            header: 'Action',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            },
            cell: ({ row: { original } }) => {
                return <Link onClick={() => handleResetModal(original.id)}
                >Reset Password</Link>
            }
        },
    ];

    const handleResetModal = (userid) => {
        //console.log("PatientFormName " + event.target.id)
        localStorage.setItem("editUserId", userid);
        setShowModal(true);
    };

    useEffect(() => {

        fetchFacilityData("");
    }, []);

    async function fetchFacilityData(facilityid) {

        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/users/list", {}, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        const results = []

        //console.log("Facilties Staff Status " + JSON.stringify(data));
        //const objData = data.data;
        let groupId = "";

        data.forEach((value) => {
            if (facilityid != "") {
                if (facilityid == value.facility_id) {
                    groupId = value.group_id;
                    results.push({
                        id: value.id,
                        name: value.username,
                        fullname: value.first_name + ' ' + value.last_name,
                        role: value.role_id == 4 ? "Group Admin" : "Staff",
                        mobile: value.phone,
                        email: value.email,
                        active: value.is_active == 1 ? "Yes" : "No",
                        lastlogin: value.last_login_timestamp
                    });
                }
            }
            else {
                groupId = value.group_id;
                results.push({
                    id: value.id,
                    name: value.username,
                    fullname: value.first_name + ' ' + value.last_name,
                    role: value.role_id == 4 ? "Group Admin" : "Staff",
                    mobile: value.phone,
                    email: value.email,
                    active: value.is_active == 1 ? "Yes" : "No",
                    lastlogin: value.last_login_timestamp
                });
            }
        });

        localStorage.setItem("groupId", groupId);

        setFacilityStaff(results);

        let facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
        console.log("Facility Points " + JSON.stringify(facilityResults));

        setOptions([
            { key: 'Select a facility', value: '' },
            ...facilityResults
        ])
    }

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        fetchFacilityData(e.target.value);
    };

    const table = useAdvanceTable({
        data: facilityStaff,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <AdvanceTableProvider {...table}>
            <Card className="mb-3">
                <Card.Body className="p-2">
                    <Row className='mt-3 justify-content-between'>
                        <Col xs="4" className="mb-3 ml-3">
                            <AdvanceTableSearchBox placeholder="Search..." />
                        </Col>
                        <Col xs="4">
                            <Form.Group>
                                <Form.Select
                                    name="facilityname"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {options.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-1 mt-1">
                            <AdvanceTable
                                headerClassName="bg-200 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs-10 mb-0 overflow-hidden'
                                }}
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </Card.Footer>
            </Card>
            <ResetPasswordModal show={showModal} setShow={setShowModal} />
        </AdvanceTableProvider>
    );
};

export default ViewFacilityStaff;
