import React, { useState } from 'react';
import { Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import MedFrequencyForm from './MedFrequencyForm.js'
import MedFrequencyTable from './MedFrequencyTable.js'
import Flex from 'components/common/Flex';

const NavItem = ({ item }) => {
    return (
        <Nav.Item as="li">
            <Nav.Link
                className="mb-0 py-3 cursor-pointer"
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};


const MedFrequency = () => {

    const [navItems] = useState(['Active', 'Inactive']);

    const [trigger, setTrigger] = useState(0);
    const [edittrigger, setEditTrigger] = useState(0);
    const [medfreqval, setMedFreqVal] = useState('');
    //let allergy_val = "";

    const callAnotherChildMethod = () => {
        //console.log("Getting Trigger " + trigger);
        setTrigger((trigger) => trigger + 1);
    };

    const callAnotherEditChildMethod = (editValue) => {
        //console.log("Getting Edit Trigger " + edittrigger + editValue);
        setEditTrigger((edittrigger) => edittrigger + 1);
        setMedFreqVal(editValue);
    };

    return (
        <Row className="g-3">
            <Col xs={12}>
                <Card className="mt-3">
                    <Card.Body>
                        <MedFrequencyForm edittrigger={edittrigger} onCallParent={callAnotherChildMethod} medfreqvalue={medfreqval} />
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={12}>
                <Card className="mt-3">
                    <Tab.Container id="audience-tab" defaultActiveKey="active">
                        <Card.Header
                            as={Flex}
                            justifyContent="between"
                            alignItems="center"
                            className="ps-0 py-0 border-bottom">
                            <Nav
                                as="ul"
                                className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret">
                                {navItems.map(item => (
                                    <NavItem item={item} key={item} />
                                ))}
                            </Nav>
                            <CardDropdown />
                        </Card.Header>

                        <Card.Body>
                            <Row className="g-1">
                                <Col xxl={12}>
                                    <Tab.Content>
                                        <Tab.Pane unmountOnExit eventKey="active">
                                            <MedFrequencyTable active_status="active" trigger={trigger} onCallEditParent={callAnotherEditChildMethod} />
                                        </Tab.Pane>
                                        <Tab.Pane unmountOnExit eventKey="inactive">
                                            <MedFrequencyTable active_status="inactive" trigger={trigger} onCallEditParent={callAnotherEditChildMethod} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </Col>
        </Row>
    );
};

export default MedFrequency;
