import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';

import SplitLogin from 'components/authentication/split/Login';
import SplitOtp from 'components/authentication/split/Otp';
import Dashboard from 'components/dashboards/default';
import AddFacilityStaff from 'components/admin/facility-staff/add-facility-staff/AddFacilityStaff';
import ViewFacilityStaff from 'components/admin/facility-staff/view-facility-staff/ViewFacilityStaff';
import ViewPatient from 'components/patient/view-patient/ViewPatient';
import PatientDetails from 'components/patient/view-patient/PatientDetails';
import AddEditPrescription from 'components/prescription/AddEditPrescription';
import MedGroup from 'components/admin/drugs/med-group/MedGroup';
import MedFrequency from 'components/admin/prescription/med-frequency/MedFrequency';
import MedAmount from 'components/admin/prescription/med-amount/MedAmount';
import MedRoute from 'components/admin/prescription/med-route/MedRoute';
import MedSplInstr from 'components/admin/prescription/med-spl-instr/MedSplInstr';
import MedReasonPres from 'components/admin/prescription/med-reason-pres/MedReasonPres';
import MedHour from 'components/admin/prescription/med-hour/MedHour';
import ViewHcp from 'components/doctors/hcp/ViewHcp';
import HcpSpeciality from 'components/doctors/hcp-speciality/HcpSpeciality';
import AddEditPatient from 'components/patient/add-patient/AddEditPatient';
import AddEditHcpForm from 'components/doctors/hcp/AddEditHcpForm';
import Allergy from 'components/admin/patient/allergy/Allergy';
import DrugList from 'components/admin/drugs/druglist/DrugList';
import Pharmacy from 'components/admin/drugs/pharmacy/Pharmacy';
import Insurance from 'components/admin/patient/insurance/Insurance';
import InsurancePriority from 'components/admin/patient/insurance-priority/InsurancePriority';
import MedRecordCategory from 'components/admin/prescription/med-record-category/MedRecordCategory';
import AddEditMedicalRecord from 'components/patient/medicalrecord/AddEditMedicalRecord';
import MedSheetForm from 'components/reports/MedSheetForm';
import Treatment from 'components/reports/Treatment';
import CurrentMed from 'components/reports/CurrentMed';
import LoaForm from 'components/reports/LoaForm';
import FacilityReportIndex from 'components/reports/FacilityReportIndex';

const routes = [
  {
    element: <App />,
    children: [
      /*{
        path: '/landing',
        element: <Landing />
      },*/
      {
        path: '/',
        element: <SplitLogin />
      },
      {
        path: '/otp',
        element: (
          <SplitOtp />
        )
      },
      {
        path: '/main',
        element: (
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            )
          },
          {
            path: rootPaths.adminRoot,
            children: [
              {
                path: paths.addFacility,
                element: (
                  <PrivateRoute>
                    <AddFacilityStaff />
                  </PrivateRoute>
                )
              },
              {
                path: paths.viewFacility,
                element: (
                  <PrivateRoute>
                    <ViewFacilityStaff />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medRoute,
                element: (
                  <PrivateRoute>
                    <MedRoute />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medGroup,
                element: (
                  <PrivateRoute>
                    <MedGroup />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medAmount,
                element: (
                  <PrivateRoute>
                    <MedAmount />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medFrequency,
                element: (
                  <PrivateRoute>
                    <MedFrequency />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medGroup,
                element: (
                  <PrivateRoute>
                    <MedGroup />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medSplInstr,
                element: (
                  <PrivateRoute>
                    <MedSplInstr />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medReasonPresc,
                element: (
                  <PrivateRoute>
                    <MedReasonPres />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medHour,
                element: (
                  <PrivateRoute>
                    <MedHour />
                  </PrivateRoute>
                )
              },
              {
                path: paths.allergy,
                element: (
                  <PrivateRoute>
                    <Allergy />
                  </PrivateRoute>
                )
              },
              {
                path: paths.drugList,
                element: (
                  <PrivateRoute>
                    <DrugList />
                  </PrivateRoute>
                )
              },
              {
                path: paths.pharmacy,
                element: (
                  <PrivateRoute>
                    <Pharmacy />
                  </PrivateRoute>
                )
              },
              {
                path: paths.insurance,
                element: (
                  <PrivateRoute>
                    <Insurance />
                  </PrivateRoute>
                )
              },
              {
                path: paths.insurancePriority,
                element: (
                  <PrivateRoute>
                    <InsurancePriority />
                  </PrivateRoute>
                )
              },
              {
                path: paths.medRecordCategory,
                element: (
                  <PrivateRoute>
                    <MedRecordCategory />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: rootPaths.patientRoot,
            children: [
              {
                path: paths.viewPatient,
                element: (
                  <PrivateRoute>
                    <ViewPatient />
                  </PrivateRoute>
                )
              },
              {
                path: paths.patientDetails,
                element: (
                  <PrivateRoute>
                    <PatientDetails />
                  </PrivateRoute>
                )
              },
              {
                path: paths.addPatient,
                element: (
                  <PrivateRoute>
                    <AddEditPatient />
                  </PrivateRoute>
                )
              },
              {
                path: paths.editPatient,
                element: (
                  <PrivateRoute>
                    <AddEditPatient />
                  </PrivateRoute>
                )
              },
            ]
          },
          {
            path: rootPaths.prescriptionRoot,
            children: [
              {
                path: paths.addPrescription,
                element: (
                  <PrivateRoute>
                    <AddEditPrescription />
                  </PrivateRoute>
                )
              },
              {
                path: paths.editPrescription,
                element: (
                  <PrivateRoute>
                    <AddEditPrescription />
                  </PrivateRoute>
                )
              },
              {
                path: paths.addMedicalRecord,
                element: (
                  <PrivateRoute>
                    <AddEditMedicalRecord />
                  </PrivateRoute>
                )
              },
              {
                path: paths.editMedicalRecord,
                element: (
                  <PrivateRoute>
                    <AddEditMedicalRecord />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: rootPaths.hcpRoot,
            children: [
              {
                path: paths.addEditHcp,
                element: (
                  <PrivateRoute>
                    <AddEditHcpForm />
                  </PrivateRoute>
                )
              },
              {
                path: paths.viewHcp,
                element: (
                  <PrivateRoute>
                    <ViewHcp />
                  </PrivateRoute>
                )
              },
              {
                path: paths.hcpSpeciality,
                element: (
                  <PrivateRoute>
                    <HcpSpeciality />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: rootPaths.reportRoot,
            children: [
              {
                path: paths.genMedSheet,
                element: (
                  <PrivateRoute>
                    <FacilityReportIndex formname="Medsheet" />
                  </PrivateRoute>
                )
              },
              {
                path: paths.genTreatment,
                element: (
                  <PrivateRoute>
                    <FacilityReportIndex formname="Treatment" />
                  </PrivateRoute>
                )
              },
              {
                path: paths.genCurrentMed,
                element: (
                  <PrivateRoute>
                    <FacilityReportIndex formname="Currentmeds" />
                  </PrivateRoute>
                )
              },
              {
                path: paths.genLoa,
                element: (
                  <PrivateRoute>
                    <FacilityReportIndex formname="Loaform" />
                  </PrivateRoute>
                )
              },
              {
                path: paths.hcpOrder,
                element: (
                  <PrivateRoute>
                    <FacilityReportIndex formname="Hcporder" />
                  </PrivateRoute>
                )
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

function PrivateRoute({ children }) {
  const isAuthenticated = checkUserAuthentication();
  return isAuthenticated ? children : <Navigate to="/" />;
}

function checkUserAuthentication() {
  if (localStorage.getItem("accessToken") == null)
    return false;
  else
    return true;
}

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
