import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import EditHcpSpecialityModal from './EditHcpSpecialityModal';

const HcpSpecialityTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'speciality',
            header: 'HCP Speciality',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updated',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.speciality + "," + original.is_active + "," + original.speciality_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [hcpSpecial, setHcpSpecial] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditHcpSpecialityModal = (specialityValue, isActive, specialityId) => {
    //     //console.log("Trager" + event.target.id);
    //     //formName = event.target.id;
    //     //setFormNameModal(event.target.id);
    //     localStorage.setItem("specialityValue", specialityValue);
    //     localStorage.setItem("specialityId", specialityId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchDoctorsSpecialityData() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctor-speciality", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const doctorsData = data.data

            if (data.status) {
                doctorsData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    //console.log(active_sts + " " + value.is_active + " " + props.active_status);
                    if (props.active_status == active_sts) {
                        results.push({
                            speciality: value.speciality,
                            // updated_by: value.updated_by,
                            speciality_id: value.id,
                            is_active: value.is_active
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setHcpSpecial(results);
            }
            else {
                toast.info("No Doctors Speciality found in this list");
            }
        }

        fetchDoctorsSpecialityData();

        async function refreshHcpSplDetails() {
            fetchDoctorsSpecialityData();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshHcpSplDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: hcpSpecial,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditHcpSpecialityModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default HcpSpecialityTable;
