import React, { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';



const ViewReportTable = (props) => {
    //const [facility] = useState(facilityStaffData);
    const [viewreport, setViewReport] = useState([]);
    const [reportflag, showReportFlag] = useState(false);

    const columns = [
        {
            accessorKey: 'id',
            header: 'ReportID',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        {
            accessorKey: 'reporttype',
            header: 'Report Type',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportformat',
            header: 'Report Format',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportdate',
            header: 'Report Date',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportlocation',
            header: 'Report Location',
            meta: {
                headerProps: { className: 'text-900' }
            },
            cell: ({ row: { original } }) => {
                return <div>{reportflag && (<Link to={original.reportlocation} >Download Report</Link>)}</div>
            }
        }
    ];

    useEffect(() => {
        // Trigger the fetch
        getReports();
    }, [props.report_type, props.trigger]);

    async function getReports() {

        console.log("I m in table" + props.report_type);

        let tokenStr = localStorage.getItem("accessToken");
        let inputReportDetails = {
            "report_type": props.report_type,
            "patient_id": localStorage.getItem("patientId"),
            "facility_id": localStorage.getItem("facilityId")
        };

        //console.log("Input Report Details " + JSON.stringify(inputReportDetails));

        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/reports/get-reports", inputReportDetails, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let results = []

        let objData = data.reportData;

        objData.forEach((value) => {
            results.push({
                id: value.id,
                reporttype: value.report_type,
                reportformat: value.report_format,
                reportdate: moment(value.report_date, 'YYYY-MM-DD').format('MM/DD/YY'),
                reportlocation: value.report_url
            });
        });

        //console.log("Report Details " + data.reportData + results.length);

        if (results.length == 0) {
            results.push({
                id: "",
                reporttype: "",
                reportformat: "No Data Found",
                reportdate: "",
                reportlocation: ""
            });
            showReportFlag(false);
        }
        else {
            showReportFlag(true);
        }

        setViewReport(results);
    }

    const table = useAdvanceTable({
        data: viewreport,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <AdvanceTableProvider {...table}>
            <AdvanceTable
                headerClassName="bg-200 text-nowrap align-middle text-center"
                rowClassName="text-nowrap align-middle text-center"
                tableProps={{
                    size: 'lg',
                    striped: true,
                    className: 'fs-10 mb-0'
                }}
            />
            <div className="mt-3">
                <AdvanceTableFooter
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
            </div>
        </AdvanceTableProvider>
    );
};

export default ViewReportTable;
