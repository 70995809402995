import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Card, Col, Form, Row, Tab, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from "axios";

const MyProfile = () => {

  let initialFormData = {
    id: '',
    fname: '',
    lname: '',
    uname: '',
    pname: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    fax: '',
    mobile: '',
    email: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProfileData() {

      let currentRoleId = 0;

      let tokenStr = localStorage.getItem("accessToken");

      await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/users/profile", { headers: { 'Authorization': `Bearer ${tokenStr}` } })
        .then((res) => {
          currentRoleId = res.data.role_id;

          setFormData({
            id: res.data.id,
            fname: res.data.first_name,
            lname: res.data.last_name,
            uname: res.data.username,
            pname: '',
            address: res.data.address,
            city: res.data.city,
            state: res.data.state,
            country: '',
            zip: res.data.zip,
            fax: res.data.fax,
            mobile: res.data.phone,
            email: res.data.email
          });

        });
    }

    // Trigger the fetch
    fetchProfileData()
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    let userProfileData =
    {
      "id": formData.id,
      "username": formData.uname,
      "email": formData.email,
      "first_name": formData.fname,
      "last_name": formData.lname,
      "phone": formData.mobile,
      "fax": formData.fax,
      "address": formData.address,
      "city": formData.city,
      "state": formData.state,
      "zip": formData.zip,
    };

    let tokenStr = localStorage.getItem("accessToken");

    console.log("userData " + JSON.stringify(userProfileData));

    let userProfileUrl = `${process.env.REACT_APP_SERVER_URL}/users/update`;

    const { data } = await axios.post(userProfileUrl, userProfileData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    console.log("data " + JSON.stringify(data));

    if (data.message == "User created successfully") {
      toast.success("Profile updated successfully");
      navigate("/");
    }
    else {
      toast.info("Unable to update profile");
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit} name="profileform">
      <Card className="mb-3">
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">First Name</Form.Label>
                <Form.Control
                  placeholder='First Name'
                  value={formData.fname}
                  name="fname"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Last Name</Form.Label>
                <Form.Control
                  placeholder='Last Name'
                  value={formData.lname}
                  name="lname"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">User Name</Form.Label>
                <Form.Control
                  placeholder='User Name'
                  value={formData.uname}
                  name="uname"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                  disabled="disabled"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Provider Name</Form.Label>
                <Form.Control
                  placeholder='Provider Name'
                  value={formData.pname}
                  name="pname"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                  disabled="disabled"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Address</Form.Label>
                <Form.Control
                  placeholder='Address'
                  value={formData.address}
                  name="address"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">City</Form.Label>
                <Form.Control
                  placeholder='City'
                  value={formData.city}
                  name="city"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">State</Form.Label>
                <Form.Control
                  placeholder='State'
                  value={formData.state}
                  name="state"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Country</Form.Label>
                <Form.Control
                  placeholder='Country'
                  value={formData.country}
                  name="country"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Zip</Form.Label>
                <Form.Control
                  placeholder='Zip'
                  value={formData.zip}
                  name="zip"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Fax</Form.Label>
                <Form.Control
                  placeholder='Fax'
                  value={formData.fax}
                  name="fax"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Mobile</Form.Label>
                <Form.Control
                  placeholder='Mobile'
                  value={formData.mobile}
                  name="mobile"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Email</Form.Label>
                <Form.Control
                  placeholder='Email'
                  value={formData.email}
                  name="email"
                  onChange={handleFieldChange}
                  type="text"
                  size="sm"
                  disabled="disabled"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col></Col>
            <Col md="3">
              <Form.Group>
                <Button variant="primary" type="submit">
                  Update Profile
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default MyProfile;
