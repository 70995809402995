import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const DrugListForm = (props) => {

    const initialFormData = {
        drug: '',
        strength: '',
        strengthvalue: '',
        ndc: '',
        generic: '',
        brand_drug: '',
        caution1: '',
        caution2: '',
        caution3: '',
        drug_form: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const formRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        populateData();

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            populateData();
        }
    }, [props.edittrigger]);

    async function populateData() {

        if (props.pharmacyid != null) {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/get-drug/` + props.drugid,
                { headers: { 'Authorization': `Bearer ${tokenStr}` } })
                .then((res) => {
                    let objDrugData = res.data.data;

                    objDrugData.forEach((value) => {
                        setFormData({
                            drug: value.drug,
                            strength: value.strength,
                            strengthvalue: value.strength_value,
                            ndc: value.ndc,
                            generic: value.generic,
                            brand_drug: value.brand_drug,
                            caution1: value.caution1,
                            caution2: value.caution2,
                            caution3: value.caution3,
                            drug_form: value.drug_form
                        })

                    });

                });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");

        let pelmedsDrugsUrl = props.status == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/drugs/add-drug" :
            `${process.env.REACT_APP_SERVER_URL}` + "/drugs/update-drug";

        let pelmedsDrugsData = props.status == "Add" ?
            {
                "pharmacy_store_name": formData.pharmacy,
                "drug": formData.drug,
                "strength": formData.strength,
                "strength_value": formData.strengthvalue,
                "ndc": formData.ndc,
                "generic": formData.generic,
                "brand_drug": formData.brand_drug,
                "caution1": formData.caution1,
                "caution2": formData.caution2,
                "caution3": formData.caution3,
                "drug_form": formData.drug_form
            } :
            {
                "id": formData.id,
                "drug": formData.drug,
                "strength": formData.strength,
                "strength_value": formData.strengthvalue,
                "ndc": formData.ndc,
                "generic": formData.generic,
                "brand_drug": formData.brand_drug,
                "caution1": formData.caution1,
                "caution2": formData.caution2,
                "caution3": formData.caution3,
                "drug_form": formData.drug_form
            }

        //console.log("pelmedsDrugsData" + JSON.stringify(pelmedsDrugsData));

        const { data } = await axios.post(pelmedsDrugsUrl, pelmedsDrugsData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status && props.status == "Add") {
            toast.success("Drugs List added successfully");
            //navigate(0);
        }
        else if ((data.status && props.status == "Edit")) {
            toast.success("Drugs List updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Drugs List");
        }

        if (props.status != "Edit") {
            setFormData(initialFormData);
            formRef.current.reset();
        }

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug</Form.Label>
                        <Form.Control
                            placeholder='Drug'
                            value={formData.drug}
                            name="drug"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Ndc</Form.Label>
                        <Form.Control
                            placeholder='Ndc'
                            value={formData.ndc}
                            name="ndc"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Strength Value</Form.Label>
                        <Form.Control
                            placeholder='Strength Value'
                            value={formData.strengthvalue}
                            name="strengthvalue"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Strength</Form.Label>
                        <Form.Control
                            placeholder='Ndc'
                            value={formData.strength}
                            name="strength"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug Form</Form.Label>
                        <Form.Control
                            placeholder='Drug Form'
                            value={formData.drug_form}
                            name="drug_form"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Generic</Form.Label>
                        <Form.Control
                            placeholder='Generic'
                            value={formData.generic}
                            name="generic"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control
                            placeholder='Brand'
                            value={formData.brand_drug}
                            name="brand_drug"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Caution 1</Form.Label>
                        <Form.Control
                            placeholder='Caution 1'
                            value={formData.caution1}
                            name="caution1"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Caution 1</Form.Label>
                        <Form.Control
                            placeholder='Caution 1'
                            value={formData.caution2}
                            name="caution2"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Caution 3</Form.Label>
                        <Form.Control
                            placeholder='Caution 3'
                            value={formData.caution3}
                            name="caution3"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            disabled={!formData.pharmacy}>
                            {props.status}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
export default DrugListForm;
