import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

const PatientModal = ({ show, setShow, handlePatientModalValue }) => {

    let formName = localStorage.getItem("patientFormName");

    //console.log("patientFormName " + formName);
    //Todo Textbox not getting cleared

    let currentModalFormValue = "";

    if (formName == "btnaddallergy")
        currentModalFormValue = "Allergy"
    else if (formName == "btnaddins")
        currentModalFormValue = "Insurance Priority"
    else if (formName == "btnaddefs")
        currentModalFormValue = "Insurance Efs"

    const [patientModalData, setPatientModalData] = useState("");

    const handleCallbackSubmit = e => {
        e.preventDefault();
        if (patientModalData.trim() != "") {
            setShow(false);
            handlePatientModalValue(patientModalData);
            setPatientModalData("");
        }
        else {
            toast.error("Empty Spaces Not allowed");
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    {formName == "btnaddefs" && (
                        <p>Patient Efs Information</p>
                    )}
                    {formName == "btnaddins" && (
                        <p>Patient Insurance Priority Information</p>
                    )}
                    {formName == "btnaddallergy" && (
                        <p>Patient Allergy Information</p>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form onSubmit={handleCallbackSubmit} name="patientmodalform">
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">{currentModalFormValue}</Form.Label>
                                <Form.Control
                                    value={patientModalData}
                                    name="patienttextbox"
                                    onChange={e => setPatientModalData(e.target.value)}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnpatientmodalsubmit"
                                    className="mt-4 w-50"
                                    disabled={!patientModalData}>
                                    Add
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

PatientModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default PatientModal;
