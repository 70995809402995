import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import '../../admin-styles/facility-styles.css';

const FacilityBasicInformation = () => {

  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Personal information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label className="required">First name:</Form.Label>
              <Form.Control
                required
                type="text"
                isInvalid={!!errors.firstName}
                {...register('firstName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label className="required">Last Name:</Form.Label>
              <Form.Control
                required
                type="text"
                isInvalid={!!errors.lastName}
                {...register('lastName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Mobile:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.mobile}
                {...register('mobile')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobile?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Zip:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.zip}
                {...register('zip')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zip?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>City:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.city}
                {...register('city')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>State:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.state}
                {...register('state')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Address:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.address}
                {...register('address')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FacilityBasicInformation;
