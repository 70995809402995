import React from 'react';
import { Card, Form } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { loaFormData } from './loaformdata.js';

const columns = [
  {
    accessorKey: 'medication',
    header: 'Medication',
    meta: {
      headerProps: {
        className: 'text-900'
      }
    }
  },
  {
    accessorKey: 'strength',
    header: 'Strength',
    meta: {
      headerProps: {
        className: 'text-end text-900'
      },
      cellProps: {
        className: 'text-end'
      }
    }
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    meta: {
      headerProps: {
        className: 'text-end text-900'
      }
    }
  },
  {
    accessorKey: 'frequency',
    header: 'Frequency',
    meta: {
      headerProps: {
        className: 'text-end text-900'
      }
    }
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    meta: {
      headerProps: {
        className: 'text-end text-900'
      }
    },
    cell: ({ row: { original } }) => {
      const { path } = original;
      return (
        <Form.Control type="text" placeholder="0.0" />
      );
    }
  }
];

const LoaFormGrid = () => {

  const table = useAdvanceTable({
    data: loaFormData,
    columns,
    selection: false,
    sortable: true,
    pagination: true,
    perPage: 10
  });

  return (
    <AdvanceTableProvider {...table}>
      <Card className="h-100">
        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
      </Card>
    </AdvanceTableProvider>
  );
};

/*LoaFormGrid.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      views: PropTypes.number.isRequired,
      time: PropTypes.string.isRequired,
      exitRate: PropTypes.string.isRequired
    })
  ).isRequired,
  perPage: PropTypes.number
};*/

export default LoaFormGrid;
