import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import { toast } from 'react-toastify';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import InsurancePriorityModal from './InsurancePriorityModal';

const InsurancePriorityTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'priority',
            header: 'Insurance Priority',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.priority + "," + original.is_active + "," + original.insurancepriority_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [insurancepriority, setInsurancePriority] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditInsurancePriorityModal = (insurancepriorityValue, isActive, insurancepriorityId) => {
    //     localStorage.setItem("insurancepriorityValue", insurancepriorityValue);
    //     localStorage.setItem("insurancepriorityId", insurancepriorityId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchInsPrDetails() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-patient-insurance-priority", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const insurancepriorityData = data.patient_insurance_priorities.data;

            //console.log("patient_insurance_priorities " + data.patient_insurance_priorities.status + ',' + data.patient_insurance_priorities.data);

            if (data.patient_insurance_priorities.status) {
                insurancepriorityData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            priority: value.priority,
                            insurancepriority_id: value.id,
                            is_active: value.is_active
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setInsurancePriority(results);
            }
            else {
                toast.info("No Insurance Priority found in this list");
            }
        }

        fetchInsPrDetails();

        async function refreshInsPrDetails() {
            fetchInsPrDetails();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshInsPrDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: insurancepriority,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <InsurancePriorityModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default InsurancePriorityTable;
