import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import PatientBasicInformation from './PatientBasicInformation';

const schema = yup
  .object({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    mobile: yup.string().optional(),
    zip: yup.string().optional(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    address: yup.string().optional(),
    email: yup.string().optional(),
    designation: yup.string().optional(),
    fax: yup.string().optional(),
    providername: yup.string().optional(),
    userName: yup.string().optional(),
    program: yup.string().optional(),
    password: yup.string().optional(),
    cnfPassword: yup.string().optional(),
    productSubCategory: yup.string().optional()
  });

const PatientDemographics = () => {

  const submittedValues = {};

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = data => {
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);

    let facilities = [];
    facilities.push(data["program"]);

    var userData = {
      "username": data["userName"],
      "password": data["cnfPassword"],
      "first_name": data["firstName"],
      "last_name": data["lastName"],
      "email": data["email"],
      "role_id": 4,
      "group_id": localStorage.getItem("groupId"),
      "facilities": facilities
    }

    let tokenStr = localStorage.getItem("accessToken");

    //console.log("userData " + userData);

    fetch(`${process.env.REACT_APP_SERVER_URL}` + "/users/create",
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenStr}` },
        body: JSON.stringify(userData)
      })
      .then(response => response.json())
      .then(function (data) {
        const items = data;
        //console.log("AddFacility " + items.message);
        if (items.message == "User created successfully") {
          toast.success('User added successfully', {
            theme: 'colored'
          });
        }
        else {
          toast.error('Unable to submit User', {
            theme: 'colored'
          });
        }
      })

    for (const key of keys) {
      submittedValues[key] = '';
    }

    reset({ ...submittedValues });
  };

  const onInvalid = (errors) => console.error("Error" + errors);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Row>
          <Col lg={12}>
            <PatientBasicInformation />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default PatientDemographics;
