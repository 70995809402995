import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import MedSheetForm from './MedSheetForm';
import CurrentMed from './CurrentMed';
import Treatment from './Treatment';
import LoaForm from './LoaForm';
import HcpOrder from './HcpOrder';
import ViewReportTable from './ViewReportTable';


const ReportTypeIndex = () => {
    const [options, setOptions] = useState([]);
    const [reportType, setReportType] = useState("medsheet");
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        populateReportTypes();
    }, []);

    async function populateReportTypes() {

        setOptions([
            { key: 'Medsheet', value: 'medsheet' },
            { key: 'Treatment', value: 'treatment' },
            { key: 'Currentmeds', value: 'currentmeds' },
            { key: 'LOA form', value: 'loaform' },
            { key: 'Hcporder', value: 'hcporder' },
        ])

    }

    const callAnotherChildMethod = () => {
        console.log("I m in parent " + reportType);
        setTrigger((trigger) => trigger + 1);
    };

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        setReportType(e.target.value);
    };

    return (
        <div>
            <Row className='mb-3 justify-content-between'>
                <Col xs="12">
                    <Form.Group>
                        <Form.Label className="required">Report Type</Form.Label>
                        <Form.Select
                            name="reporttype"
                            onChange={handleFieldChange}
                        >
                            {options.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3 justify-content-between'>
                <Col xs="12" className="mb-1 mt-1">
                    {reportType == "medsheet" &&
                        <MedSheetForm onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "currentmeds" &&
                        <CurrentMed onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "treatment" &&
                        <Treatment onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "loaform" &&
                        <LoaForm onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "hcporder" &&
                        <HcpOrder onCallParent={callAnotherChildMethod} />
                    }
                </Col>
            </Row>
            <Row className='mb-3 justify-content-between'>
                <ViewReportTable report_type={reportType} trigger={trigger} />
            </Row>
        </div>
    );
};

export default ReportTypeIndex;