export const prescriptionType = [
    {
        key: 'Current',
        value: 'current',
    },
    {
        key: 'Discontinued',
        value: 'discontinued',
    },
    {
        key: 'Refill',
        value: 'refill',
    },
    {
        key: 'Unverified',
        value: 'unverified',
    }
];