import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import axios from "axios";
import IconItem from 'components/common/icon/IconItem';
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import EditMedGroupModal from './EditMedGroupModal';


const MedGroupTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'group',
            header: 'Med Group',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.group + "," + original.is_active + "," + original.group_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [medGroup, setMedGroup] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditMedGroupModal = (groupValue, isActive, groupId) => {
    //     localStorage.setItem("groupValue", groupValue);
    //     localStorage.setItem("groupId", groupId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchMedGrpDetails() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/drugs/get-drug-group", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const drugGroupData = data.data

            if (data.status) {
                drugGroupData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";

                    if (props.active_status == active_sts) {
                        results.push({
                            group: value.name,
                            group_id: value.id,
                            is_active: value.is_active// == 1 ? 0 : 1
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setMedGroup(results);
            }
            else {
                toast.info("No MedGroup found in this list");
            }
        }

        fetchMedGrpDetails();

        async function refreshMedGrpDetails() {
            fetchMedGrpDetails();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshMedGrpDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: medGroup,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditMedGroupModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedGroupTable;
