import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import moment from 'moment';
import { prescriptionType } from './prescriptionType.js';

const columns = [
    {
        accessorKey: 'orderid',
        header: 'Id',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return <Link to={{ pathname: paths.editPrescription }} state={{
                orderid: original.orderid, patientid: original.patientid, facilityid: original.facility_id
            }} >{original.orderid}</Link>
        }
    },
    {
        accessorKey: 'drug',
        header: 'Drug',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'generic',
        header: 'Generic',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'amount',
        header: 'Amount',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'dose',
        header: 'Dose',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    // {
    //     accessorKey: 'frequency',
    //     header: 'Frequency',
    //     meta: {
    //         headerProps: { className: 'text-900 text-start' },
    //         cellProps: { className: 'text-start' }
    //     }
    // },
    {
        accessorKey: 'start',
        header: 'Start',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },

];

const CurrentPrescription = (props) => {
    //const [facility] = useState(facilityStaffData);
    const [currentPrescription, setCurrentPrescription] = useState([]);
    const [prescriptionTypeOptions, setprescriptionTypeOptions] = useState([]);

    useEffect(() => {

        async function fetchAllPrescriptionData() {

            //console.log("props.patientid current " + props.patientid);

            setprescriptionTypeOptions([
                ...prescriptionType
            ]);

            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + `/prescriptions/list-all-prescriptions/` + props.patientid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = []

            //console.log("Prescription Data Status " + data.prescriptions.status);
            let objData = data.prescriptions.data;
            let groupId = "";

            //console.log("prescriptionData" + JSON.stringify(objData));

            localStorage.setItem("prescriptionObjData", JSON.stringify(objData));

            filterPrescriptionData(objData);
        }

        // Trigger the fetch
        fetchAllPrescriptionData();
    }, []);

    const table = useAdvanceTable({
        data: currentPrescription,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    const handleFieldChange = e => {
        //console.log(e.target.value);
        let prescriptionResults = JSON.parse(localStorage.getItem("prescriptionObjData"));

        let filtered_prescription = "";

        if (e.target.value == "current")
            filtered_prescription = prescriptionResults.filter(element => element.discontinued_date == null)
        else if (e.target.value == "discontinued")
            filtered_prescription = prescriptionResults.filter(element => element.discontinued_date != null)
        else if (e.target.value == "refill")
            filtered_prescription = prescriptionResults.filter(element => element.refill_date != null)
        else if (e.target.value == "unverified")
            filtered_prescription = prescriptionResults.filter(element => element.verified_by == null)

        filterPrescriptionData(filtered_prescription);
    }

    function filterPrescriptionData(filtered_prescription) {

        let results = [];
        filtered_prescription.forEach((value) => {

            let stop_date = value.stop_date != null ? moment(value.stop_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;
            let pharmacy_expire_date = value.pharmacy_expire_date != null ? moment(value.pharmacy_expire_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;
            let start_date = value.start_date != null ? moment(value.start_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;

            results.push({
                orderid: value.id,
                hcp: value.doctor_lastname + ", " + value.doctor_firstname,
                drug: value.drug,
                generic: value.generic,
                amount: value.qty,
                dose: value.days_supply,
                frequency: value.no_of_refill,
                route: '',
                start: start_date,
                stop: stop_date,
                expiry: pharmacy_expire_date,
                facility_id: value.facility_id,
                patient_id: value.patient_id,
                ndc: value.drug_ndc,
                drug_form: value.drug_form,
                brand_drug: value.brand_drug,
                generic: value.generic,
                patient_name: props.username,
                earlytime: value.time_1,
                exceedtime: value.time_2,
                medstime: value.time_3,
                hourtime1: value.time_4,
                hourtime2: value.time_5,
                hourtime3: value.time_6,
                hourtime4: value.time_7,
                isvital: value.has_vitalsigns == null ? 0 : 1,
                patientid: props.patientid
            });
        });

        setCurrentPrescription(results);

    }

    return (
        <AdvanceTableProvider {...table}>
            <Row >
                <Col xs="4">
                    <Form.Group>
                        <Form.Label>Prescription Type</Form.Label>
                        <Form.Select
                            name="prescriptiontype"
                            onChange={handleFieldChange}
                            size="sm"
                        >
                            {prescriptionTypeOptions.map((option) => {
                                return (
                                    <option key={option.value} value={option.value} >
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="4"></Col>
                <Col xs="4">
                    <Form.Label></Form.Label>
                    <AdvanceTableSearchBox placeholder="Search..." />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle text-center"
                        rowClassName="text-nowrap align-middle text-center"
                        tableProps={{
                            size: 'lg',
                            striped: true,
                            className: 'fs-10 mb-0'
                        }}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Col>
            </Row>
        </AdvanceTableProvider>
    );
};

export default CurrentPrescription;;