import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customerDetailsData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import EditMedRouteModal from './EditMedRouteModal';

const MedRouteTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'route',
            header: 'Route Name',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.route + "," + original.is_active + "," + original.route_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [medRoute, setMedRoute] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditMedRouteModal = (routeValue, isActive, routeId) => {
    //     //Todo need to use different approach of sending data to modal, and create one component for medroute, hcpspeciality and others
    //     localStorage.setItem("routeValue", routeValue);
    //     localStorage.setItem("routeId", routeId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchDrugsRoute() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/drugs/get-drug-route", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const drugRouteData = data.data

            if (data.status) {
                drugRouteData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            route_id: value.id,
                            route: value.route,
                            is_active: value.is_active// == 1 ? 0 : 1
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setMedRoute(results);
            }
            else {
                toast.info("No Drug Route found in this list");
            }
        }

        fetchDrugsRoute();

        async function refreshMedRouteDetails() {
            fetchDrugsRoute();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshMedRouteDetails();
        }
    }, [props.trigger]);

    const table = useAdvanceTable({
        data: medRoute,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle text-center"
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditMedRouteModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedRouteTable;
