import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';


const MedHourForm = (props) => {

    const initialFormData = {
        hrtime: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const formRef = useRef(null);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setMedHrDetails() {
            //console.log("set edit details " + props.allergyvalue);
            setFormData({
                hrtime: props.medhrvalue.split(",")[0],
                isactive: props.medhrvalue.split(",")[1] == 1 ? true : false,
                id: props.medhrvalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setMedHrDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsMedHourUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/add-prescription-hour" : `${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/update-prescription-hour";
        let pelmedsMedHourData = buttonStatus == "Add" ? { "time": formData.hrtime, "is_active": formData.isactive ? 1 : 0 } :
            { "time": formData.hrtime, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        //console.log("pelmedsMedHourData" + JSON.stringify(pelmedsMedHourData));

        const { data } = await axios.post(pelmedsMedHourUrl, pelmedsMedHourData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status && buttonStatus == "Add") {
            toast.success("Med Hour added successfully");
            //navigate(0);
        }
        else if ((data.status && buttonStatus == "Edit")) {
            toast.success("Med Hour updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Med Hour");

        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        //}
        setButtonStatus("Add");

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Hour</Form.Label>
                        <Form.Control
                            placeholder='Time'
                            value={formData.hrtime}
                            name="hrtime"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            disabled={!formData.hrtime}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type='switch'
                            name="isactive"
                            id='isactive'
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
export default MedHourForm;
