import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const MedRecordCategoryForm = (props) => {

    const initialFormData = {
        code: '',
        facilityname: '',
        description: '',
        is_medsheet: false,
        is_treatment: false,
        is_physpres: false
    };

    const [formData, setFormData] = useState(initialFormData);
    const [facilityoptions, setFacilityOptions] = useState([]);
    const formRef = useRef(null);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    //const navigate = useNavigate();

    useEffect(() => {
        //populateData();

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
            populateData();
        }

        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setFacilityOptions([
            { key: 'Select a facility', value: '' },
            ...results
        ]);


    }, [props.edittrigger]);

    async function populateData() {
        let tokenStr = localStorage.getItem("accessToken");

        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setFacilityOptions([
            { key: 'Select a facility', value: '' },
            ...results
        ]);

        //if (props.medrecordcategoryid != null) {
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/patients/get-medical-record-category/` + props.medrecordcategoryid,
            { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                let objMedRecCatData = res.data.data;

                objMedRecCatData.forEach((value) => {
                    setFormData({
                        code: value.code,
                        description: value.description,
                        is_medsheet: value.mar_flag ? false : true,
                        is_treatment: value.tar_flag ? false : true,
                        is_physpres: value.po_flag ? false : true,
                    })

                });

            });
        //}
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");

        let pelmedsMedRecCatUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/patients/add-medical-record-category" :
            `${process.env.REACT_APP_SERVER_URL}` + "/patients/update-medical-record-category";

        let pelmedsMedRecCatData = buttonStatus == "Add" ?
            {
                "code": formData.code,
                "description": formData.description,
                "mar_flag": formData.is_medsheet ? 1 : 0,
                "tar_flag": formData.is_treatment ? 1 : 0,
                "po_flag": formData.is_physpres ? 1 : 0
            } :
            {
                "id": props.medrecordcategoryid,
                "code": formData.code,
                "description": formData.description,
                "mar_flag": formData.is_medsheet ? 1 : 0,
                "tar_flag": formData.is_treatment ? 1 : 0,
                "po_flag": formData.is_physpres ? 1 : 0
            }

        //console.log("pelmedsMedRecCatData" + JSON.stringify(pelmedsMedRecCatData));

        const { data } = await axios.post(pelmedsMedRecCatUrl, pelmedsMedRecCatData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status && buttonStatus == "Add") {
            toast.success("Medical Record Category added successfully");
            //navigate(0);
        }
        else if ((data.status && buttonStatus == "Edit")) {
            toast.success("Medical Record Category updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Medical Record Category");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Facility</Form.Label>
                        <Form.Select
                            name="facilityname"
                            onChange={handleFieldChange}
                            size="sm"
                        >
                            {facilityoptions.map((option) => {
                                let isFacilitySelected = false;
                                if (props.medrecordcategoryid != null) {
                                    if (option.value == formData.facilityname) {
                                        isFacilitySelected = "selected";
                                    }
                                }
                                return (
                                    <option key={option.value} value={option.value} selected={isFacilitySelected} >
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            placeholder='Code'
                            value={formData.code}
                            name="code"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            placeholder='Description'
                            value={formData.description}
                            name="description"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_medsheet"
                        id='is_medsheet'
                        checked={
                            formData.is_medsheet
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_medsheet: e.target.checked
                            })
                        }
                        label='Medsheet'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_treatment"
                        id='is_treatment'
                        checked={
                            formData.is_treatment
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_treatment: e.target.checked
                            })
                        }
                        label='Treatment'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_physpres"
                        id='is_physpres'
                        checked={
                            formData.is_physpres
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_physpres: e.target.checked
                            })
                        }
                        label='Physician Prescription'
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            disabled={!formData.code}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
export default MedRecordCategoryForm;
